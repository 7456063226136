/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState, useMemo } from "react"
import { Container, Header, Body } from "../../../components/TableList/styles"
import { generatePaymentLink, getAddressNContact, getShopkeeperMCC } from "../../../services/paymentlinks"
import { ColorBaseBlueDark, ColorBaseBlueDark2 } from "../../../theme/tokens"
import { SelectInput, TextAreaInput, WrapperBox, WrapperLinkGenerator } from "../styles"
import check from "../../../assets/check_hight.png"
import ReactModal from "react-modal";
import { ContainerModal } from "../../MeusDados/styles"
import { AiOutlineCloseCircle } from "react-icons/ai";
import { toast } from 'react-toastify';
import Button from "../../../components/Button/Button"
import Input from "../../../components/Input"
import { optionsInstallments } from "./contants"
import { formatToCurrencyBRL } from "../../../utils/functions"

export const PaymentLinkGenerator: React.FC = () => {
  const [linkURL, setLinkURL] = useState<null | string>(null);
  const [payments, setPayments] = useState<string | undefined>("1");
  const [value, setValue] = useState<string>("");
  const [name, setName] = useState<string | undefined>("");
  const [description, setDescription] = useState<string | undefined>("");
  const [addressNContact, setAddressNContact] = useState<any>(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  useEffect(() => {
    (async () => {
      const addressNContact: any = await getAddressNContact();
      setAddressNContact(addressNContact);
      setName(`PROPIG*${window.localStorage.getItem('name') || ""}`);
      const lojista = (name || "").split("*")[1];

    })()
  }, [])


  const maxValue = useMemo<number>(() => {
    const documento = window.localStorage.getItem('documento') || "";
    const DEFAULT_MAX_VALUE = 3000;
    const cpnjLiberados = ["26164580000146", "31808592000104"]
    const cpnjPersonality = {
      "21169536000105": 8000,
      "45917220000112": 9000,
      "31198514000127": 50000,
    }

    if (cpnjPersonality[documento as keyof typeof cpnjPersonality]) {
      return cpnjPersonality[window.localStorage.getItem('documento') as keyof typeof cpnjPersonality];
    }

    if (addressNContact === null) {
      return DEFAULT_MAX_VALUE;
    }

    if (addressNContact.distributor) {
      return 1000000000000000000000000;
    }

    if (cpnjLiberados.includes(documento)) {
      return 20000;
    }

    return DEFAULT_MAX_VALUE;
  }, [addressNContact])

  const generageURL = useCallback(async () => {
    if (addressNContact === null) {
      setShowConfirmationModal(false);
      toast.error("Problema ocorrido durante geração de link de pagamento");
      return;
    }

    setShowConfirmationModal(false);

    const mcc: any = await getShopkeeperMCC()
    const document = window.localStorage.getItem('documento');
    const nameValue = name || ""; 
    const lojista = (nameValue || "").split("*")[1];

    try {
      const URL = await generatePaymentLink(parseFloat(value || "0"), parseInt(payments || "1"), nameValue, parseFloat(value || "0") / parseFloat(payments || "1"), document!, lojista, description || "", addressNContact, mcc)
      setLinkURL(URL);
    } catch (e) {
      toast.error("Problema ocorrido durante geração de link de pagamento");
    }

  }, [payments, name, value, description])

  const copyURL = useCallback(async () => {
    if (linkURL === null) {
      return
    }

    await navigator.clipboard.writeText(linkURL)

  }, [linkURL])

  const cancel = useCallback(() => {
    setPayments("1");
    setValue("0");
    setDescription("");
  }, [])

  useEffect(() => {
    if (parseFloat(value || "0") > maxValue) {
      setValue(`${maxValue}`);
    }
  }, [value, maxValue])

  const modalEstilo = {
    content: {
      width: '80%',
      maxWidth: "500px",
      maxHeight: 'min-content',
      margin: 'auto',
      borderRadius: "15px",
      boxShadow: "0.5rem 0.5rem 0.5rem rgba(0, 0, 0, 0.212)",
      borderColor: "#F6F6F6",
    }
  }

  return (
    <WrapperLinkGenerator>
      <div className="form-link-generator">
        {linkURL === null && (
          <WrapperBox style={{ padding: "1rem 2rem" }}>
            <p style={{ color: ColorBaseBlueDark, fontWeight: "bold" }}>Identificador da venda para o cliente</p>
            <Input
              id="sell_identity"
              width="100%"
              value={name}
              onChange={(e: any) => {
                if (e.target.value !== name) {
                  e.preventDefault();
                  setName(name);
                  toast.warn("O identificador da venda não pode ser alterado");
                }
              }}
              placeHolder="Digite aqui a identificação da venda"
              textExample="Como aparecerá na fatura do cliente"
              disabled
            />
            <Input
              type="number"
              id="value"
              width="100%"
              value={value}
              onChange={(e: any) => setValue(e.target.value)}
              placeHolder="Valor"
              textExample="Valor máximo R$ 3.000,00"
            />
            <SelectInput
              id="inputGroupSelect01"
              placeholder="Parcelas"
              value={payments}
              onChange={e => {
                setPayments(e.target.value);
              }}
            >
              {optionsInstallments?.map((item) => (
                <option value={item?.value} key={item?.value}>{item?.label}</option>
              ))}
            </SelectInput>
            <TextAreaInput
              value={description}
              onChange={e => setDescription(e.target.value)}
              placeholder="Descrição dos bens e/ou serviços vendidos/adquiridos"
              rows={4}
            />
            <div className="content-buttons">
              <Button
                width="25%"
                onClick={() => { cancel() }}
                label="Cancelar"
                typeButton="outline"
              />
              <Button
                width="25%"
                disabled={payments === undefined || name === "" || value === undefined || value === "0" || value === "" || description === "" || window.localStorage.getItem('documento') === "22673932000138"}
                onClick={() => { setShowConfirmationModal(true) }}
                label="Gerar link"
                typeButton={"primary"}
              />
            </div>
          </WrapperBox>
        )}
        {linkURL !== null && (
          <Container style={{ backgroundColor: "white", padding: "1rem 3rem" }}>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%", padding: "5rem 0", flexDirection: "column" }}>
              {/* <div style={{textAlign: "center"}}> */}
              <p style={{ color: ColorBaseBlueDark2, fontSize: "24px", fontWeight: "700" }}>Criação de Link Concluída</p>
              <img src={check} alt="check" style={{ marginBottom: "1rem" }} />
              <span style={{ fontSize: "0.8rem", padding: "0.5rem", borderRadius: "0.5rem", color: "#AAA", backgroundColor: "rgba(199, 203, 209, 0.20)", width: "80%", userSelect: "none", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{linkURL}</span>
              <br />
              <Button onClick={() => { copyURL() }} label="Clique aqui para copiar o link" typeButton="outline" fontSize="14px" />
              {/* </div> */}
            </div>
          </Container>
        )}
      </div>

      {/* ------------ COLUNA RESUMO ------------ */}

      <div className="resume">

        {/* ------------ COLUNA RESUMO -> DADOS ------------ */}

        <Container style={{ height: "auto" }}>
          <Header>
            <p>Maquininha virtual</p>
          </Header>
          <Body>
            <div className="row-details">
              <p>Valor total</p>
              <span>{formatToCurrencyBRL(value)}</span>
            </div>
            <div className="row-details">
              <p>Nº de parcelas</p>
              <span>{payments}</span>
            </div>
            <div className="row-details">
              <p>Valor por parcela</p>
              <span>{formatToCurrencyBRL(parseFloat(value || "0") / parseFloat(payments || "1"))}</span>
            </div>
          </Body>
        </Container>

        {/* ------------ COLUNA RESUMO -> ALERTA ------------ */}

        <Container style={{ backgroundColor: "white", padding: "1.5rem", marginTop: "1rem", fontFamily: "Roboto", height: "auto" }}>
          <p style={{ color: ColorBaseBlueDark, fontSize: "24px", fontWeight: "700" }}>Atenção</p>
          <p style={{ color: ColorBaseBlueDark2, fontSize: "12px" }}>Por se tratar de uma venda não presencial, sem o uso do chip ou senha, o CLIQUE PAGUE aumenta o risco de CANCELAMENTO DA VENDA POR SOLICITAÇÃO DO PORTADOR DO CARTÃO junto ao seu emissor (chargeback). <br /><br />Sempre que possível, utlize nossas maquininhas de forma presencial para ter garantia da conclusão da venda. </p>
        </Container>
      </div>




      {/* ------------ MODAL CONFIRMAÇÃO ------------ */}

      <ReactModal
        isOpen={showConfirmationModal}
        onRequestClose={() => setShowConfirmationModal(false)}
        style={modalEstilo}
      >
        <ContainerModal>
          <div className='content-head'>
            <div></div>
            <Button
              icon={<AiOutlineCloseCircle color='#002868' />}
              typeButton="outline"
              noBorder
              width='40px'
              onClick={() => { setShowConfirmationModal(false) }}
            />
          </div>
          <div style={{ backgroundColor: "white", padding: "1.5rem", marginTop: "1rem", fontFamily: "Roboto", height: "auto" }}>
            <p style={{ color: ColorBaseBlueDark, fontSize: "24px", fontWeight: "700" }}>Atenção</p>
            <p style={{ color: ColorBaseBlueDark2, fontSize: "12px" }}>Por se tratar de uma venda não presencial, sem o uso do chip ou senha, o CLIQUE PAGUE aumenta o risco de CANCELAMENTO DA VENDA POR SOLICITAÇÃO DO PORTADOR DO CARTÃO junto ao seu emissor (chargeback). <br /><br />Sempre que possível, utlize nossas maquininhas de forma presencial para ter garantia da conclusão da venda. </p>
            <div style={{ display: "flex", marginTop: "2rem" }}>
              <Button width="50%" type='submit' label="Cancelar" typeButton="outline" onClick={() => setShowConfirmationModal(false)} />
              <Button width="50%" type='submit' label="Confirmar" typeButton="primary" onClick={() => { generageURL() }} />
            </div>
          </div>

        </ContainerModal>
      </ReactModal>

    </WrapperLinkGenerator>



  )
}